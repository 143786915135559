.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  /* background-color: #eeeeee; */
  margin: 0;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.35em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1.2em;
  line-height: 0em;
  text-transform: uppercase;
  font-weight: 500;
}
