@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
}
.MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}
.Notification-list li:nth-child(even) {
  background: aliceblue;
}
.Notification-list li:hover {
  background: #ec407a;
  color: white;
  font-weight: bold;
}

/* .MuiDataGrid-row:nth-child(even) {
  background-color: lightcyan;
} */
/* .MuiDataGrid-cell {
  border: .5px solid lightgray !important;
} */

.MuiDataGrid-root {
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
}

/* .MuiDataGrid-actionsCell {
  background-color: '#ffffff
} */
.clickable {
  cursor: pointer;
}
.MuiAvatar-circular {
  transition: transform 0.2s;
}
.MuiAvatar-circular:hover {
  transform: scale(1.2);
}

/* Title Section for new student and application in homepage */
.DataGridTitleBox {
  /* background-color: #371B58; #495579 #263159 #181D31 #282A3A #181D31*/
  background-color: #181d31;
  border-radius: 8px 8px 0px 0px;
  margin: 0px 0px;
  padding: 6px 12px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(88, 88, 88, 0.2);
  font-weight: 400;
  color: #ffffff;
  /* color: rgb(53, 53, 53); */
}

/* .MuiDataGrid-columnHeaders  {
  background-color: rgba(17, 170, 226, 0.1);
} */

.MuiBoxFlex {
  display: flex;
  justify-content: space-between;
}

.box1 img {
  /* object-fit: 'fit'; */
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 10px 10px;
}

.bgImage {
  background-image: url('../images/gradient-bg.png');
  -webkit-background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: cover;
  border-radius: 8px 8px 8px 8px;
}

.world-map {
  max-width: 400px;
}

@media (max-width: 1600px) {
  .world-map {
    max-width: 350px;
  }
}

#sidebar a.active {
  color: red;
}

.sidebar-item a.active {
  color: red;
}

classes.regularLink .active {
  color: red;
}

.active {
  background-color: #11aae2;
}

.studentSidebarBgGradient0 {
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}

.studentSidebarBgGradient1 {
  background-color: #21d4fd;
  background-image: linear-gradient(30deg, #21d4fd 0%, #b721ff 100%);
}
.studentSidebarBgGradient2 {
  background-color: #8bc6ec;
  background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
}
.studentSidebarBgGradient3 {
  background: hsla(206, 91%, 66%, 1);

  background: linear-gradient(45deg, hsla(206, 91%, 66%, 1) 0%, hsla(190, 90%, 51%, 1) 100%);
  
  background: -moz-linear-gradient(45deg, hsla(206, 91%, 66%, 1) 0%, hsla(190, 90%, 51%, 1) 100%);
  
  background: -webkit-linear-gradient(45deg, hsla(206, 91%, 66%, 1) 0%, hsla(190, 90%, 51%, 1) 100%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#5AB2F7", endColorstr="#12CFF3", GradientType=1 );
}

.studentBtnPrimary {
  background: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
  @apply text-white py-3 px-6 shadow-md transition-all duration-300 ease-in-out;
}

.studentBtnPrimary:hover {
  background: linear-gradient(19deg, #1ad5ff 0%, #a718ea 100%);
  box-shadow: 0 0 15px rgba(33, 212, 253, 0.4);
}

/* All ScrollBar styling below */

/* Mozilla Scrollbar */
.mozilla-scroller {
  scrollbar-width: thin;
  scrollbar-color: #ffffff #e6e7eb;
  /* background-color: #062044; */
  outline: 0px solid;
  transition: 2s;

  overflow-y: scroll;
}

/* Main Scrollbar */
::-webkit-scrollbar {
  width: 0.7em;
}

::-webkit-scrollbar-track {
}

::-webkit-scrollbar-thumb {
  background-color: #596380;
  outline: 0px solid;
  transition: 2s;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
  background: #495579;
}

/* Student Applications List Scrollbar */
.app-scrollbar::-webkit-scrollbar {
  height: 0.7em;
}

.app-scrollbar::-webkit-scrollbar-track {
}

.app-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  outline: 0px solid;
  transition: 2s;
  border-radius: 30px;
}

.app-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #36eeff;
}

/* Smaller Internal Scrollbar*/

.minimal-scrollbar::-webkit-scrollbar {
  width: 0.4em;
}

.minimal-scrollbar::-webkit-scrollbar-thumb {
  background-color: #dbdee2;
  outline: 0px solid;
  transition: 2s;
  border-radius: 25px;
}

.minimal-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c1c9df;
}
.minimal-scrollbar::-webkit-scrollbar-track {
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
