.body {
  color: rgb(31, 45, 62);
}
.MuiButtonBase-root {
  /* not probably the best practice to target mui class in this way todo mir */
  border-radius: 0.5rem;
  text-transform: none;
}
.question {
  font-weight: 500;
  line-height: 1.25;
  font-size: 1.25rem;
}

.register-step {
  transition: all 0.3s ease-out;
}
.register-step-open {
  animation: registerStepOpenKf 0.4s ease-out forwards;
}
.register-step-close {
  animation: registerStepCloseKf 0.4s ease-out forwards;
}

@keyframes registerStepOpenKf {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 1;
    transform: translateY(90%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes registerStepCloseKf {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    transform: translateY(60%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.step-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
}
.step-listItem {
  margin: 0;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  text-align: center;
  cursor: pointer;
  margin-top: 1px;
  list-style-type: none;
}

.step-listItem:hover,
.step-listItem:active {
  background-color: #ccc;
}
