@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-studentNavLink {
    @apply w-[33%] h-24 lg:p-2 xl:p-3 2xl:p-6 m-2 bg-gray-100 rounded-2xl text-xl text-gray-800 shadow drop-shadow font-semibold transition-all duration-150;
  }
  ul {
    list-style: inside;
    margin: 0 auto;
    padding-left: 20px;
  }
  ol {
    list-style: inside;
    margin: 0 auto;
    padding-left: 20px;
    list-style-type: decimal;
  }
  /* Tailwind CSS Classes for ProgramLists in Student Component */
  /* src/components/Students/Student/ProgramLists/ProgramLists.js */
  .head-stu-filter {
    @apply my-2 p-4 lg:bg-indigo-200 rounded-lg text-lg font-semibold text-indigo-800 w-96 lg:w-full  items-center;
  }
  .head-stu-filter-top {
    @apply p-2  w-96 lg:w-full flex flex-col lg:flex-row gap-4 items-center;
  }
  .head-stu-filter-bottom {
    @apply p-2  w-96 lg:w-full flex flex-col lg:flex-row gap-4 items-center;
    @apply my-2 p-4 lg:bg-indigo-200 rounded-lg text-lg font-semibold text-indigo-800 w-96 lg:w-full  items-center;
  }
  .head-stu-filter-top {
    @apply p-2  w-96 lg:w-full flex flex-col lg:flex-row gap-4 items-center;
  }
  .head-stu-filter-bottom {
    @apply p-2  w-96 lg:w-full flex flex-col lg:flex-row gap-4 items-center;
  }
  .filter-stu-search-btn {
    @apply bg-indigo-100 p-1.5 w-60 rounded-md text-indigo-600 hover:text-indigo-700 transition-all duration-100 shadow-md hover:scale-102;
  }
  .head-stu-programSearch {
    @apply w-full text-xl text-gray-700 shadow-xl font-semibold  rounded-lg p-4 cursor-default;
  }
  .sidebar-stu-programList {
    @apply w-[35%] p-1 min-w-[400px] max-h-screen rounded-lg bg-indigo-50 overflow-scroll;
  }
  .sidebar-stu-program-card {
    @apply flex flex-col gap-2 p-3 hover:py-4 bg-white hover:bg-indigo-400 hover:text-white transition-all duration-75 rounded-xl drop-shadow cursor-pointer;
  }
}
